<template>
  <form class="max-w-3xl mx-auto space-y-8 divide-y divide-gray-200">
    <div class="space-y-8 divide-y divide-gray-200">
      <div>
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            EdgDeploy
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            This will automatically deploy your branch to the servers you select
          </p>
        </div>

        <div class="mt-6 text-left">
          <div class="sm:col-span-4">
            <label for="branch" class="block text-sm font-medium text-gray-700">
              Branch
            </label>
            <div class="mt-1 flex rounded-md shadow-sm">
            <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
              touchsms-messageport/
            </span>
              <input type="text" id="branch" v-model="branch" class="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300">
            </div>
          </div>
          <div class="mt-4 space-y-4">
            <label for="branch" class="block text-sm font-medium text-gray-700">
              Servers
            </label>
            <div class="relative flex items-start">
              <div class="flex items-center h-5">
                <input id="mp" v-model="servers" value="mp" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded">
              </div>
              <div class="ml-3 font-medium text-sm text-gray-700">
                Messageport Staging
              </div>
            </div>
            <div class="relative flex items-start">
              <div class="flex items-center h-5">
                <input id="touch" v-model="servers" value="touch" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded">
              </div>
              <div class="ml-3 font-medium text-sm text-gray-700">
                TouchSMS Staging
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="pt-5">
      <div class="flex justify-end">
        <button type="submit" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Deploy
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'Deploy',
  data() {
    return {
      branch: null,
      servers: [],
    }
  }
}
</script>

<style scoped>

</style>
